import React, { useState } from "react";
import "../App.css"; // Create a CSS file for custom styling
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ContactSection = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); // Loading state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    })

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateFormFields = (formFieldName = null) => {
        let isValid
        const regexes = {
            name: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            subject: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            phone: /^\+?\d{10,15}$/,
            message: /^(?!\s+$)[^\s].{0,1999}$/,
        }

        if (formFieldName) {
            isValid = regexes[formFieldName].test(formData[formFieldName])
            if (!isValid)
                document.querySelector(formFieldName !== 'message' ? `#contact input[name=${formFieldName}]` : `#contact textarea[name=${formFieldName}]`).classList.add('is-invalid')
            else
                document.querySelector(formFieldName !== 'message' ? `#contact input[name=${formFieldName}]` : `#contact textarea[name=${formFieldName}]`).classList.remove('is-invalid')
        } else {
            for (const key in regexes) {
                if (Object.prototype.hasOwnProperty.call(regexes, key)) {
                    const regex = regexes[key]
                    isValid = regex.test(formData[key])

                    if (!isValid)
                        document.querySelector(key !== 'message' ? `#contact input[name=${key}]` : `#contact textarea[name=${key}]`).classList.add('is-invalid')
                    else
                        document.querySelector(key !== 'message' ? `#contact input[name=${key}]` : `#contact textarea[name=${key}]`).classList.remove('is-invalid')

                    if (!isValid)
                        return false
                }
            }
            return true
        }
    }


    const handleSubmit = async e => {
        e.preventDefault()

        // Email & phone validation
        if (!validateFormFields())
            return

        setLoading(true)

        await fetch("https://theappdesign.com/php_mailer/index.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
            .then(r => {
                if (!r.ok) {
                    throw new Error(`HTTP error! status: ${r.status}`);
                }
                return r.json();
            })
            .then(({ status, message }) => {
                setLoading(false);
                if (status === 'success') {
                    navigate('/thank-you');
                } else {
                    Swal.fire('Error', message, 'error');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
                Swal.fire('Error', 'Failed to submit the form. Please try again later.', 'error');
            });

    }

    return (
        <section className="custom-contact-section py-5 get-started-section" id="contact">
            <div className="container py-5 px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                    <h2 className="">Get In Touch!</h2>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <p className="text-center mb-4">Have questions, or are you ready to start your project? Reach out to us!</p>
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                placeholder="Your Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                onKeyUp={() => validateFormFields('name')}
                                            />
                                            <label htmlFor="name">Your Name <span className="text-danger">*</span></label>
                                            <div className="invalid-feedback">
                                                Not allowed more than 50 characters and it must be alphabetic
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                placeholder="Your Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                onKeyUp={() => validateFormFields('email')}
                                            />
                                            <label htmlFor="email">Your Email <span className="text-danger">*</span></label>
                                            <div className="invalid-feedback">
                                                Invalid Email address
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="subject"
                                                placeholder="Subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                                onKeyUp={() => validateFormFields('subject')}
                                            />
                                            <label htmlFor="subject">Subject <span className="text-danger">*</span></label>
                                            <div className="invalid-feedback">
                                                Not allowed more than 50 characters and it must be alphabetic
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-6  ">
                                        <div className="form-floating">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                placeholder="Contact Number*"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                                onKeyUp={() => validateFormFields('phone')}
                                            />
                                            <label htmlFor="phone">Phone <span className="text-danger">*</span></label>
                                            <div className="invalid-feedback">
                                                Phone number must be between 10 to 15 digits and can include "+" symbol.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Leave a message here"
                                                name="message"
                                                style={{ height: "150px" }}
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                onKeyUp={() => validateFormFields('message')}
                                            ></textarea>
                                            <label htmlFor="message">Message <span className="text-danger">*</span></label>
                                            <div className="invalid-feedback">
                                                Whitespaces are not allowed in beginning & message should not be more than 2000 characters.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-primary-gradient rounded-pill py-3 px-5" disabled={loading}>
                                            {loading ? (
                                                <>
                                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                                    <span role="status">Submitting...</span>
                                                </>
                                            ) : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
