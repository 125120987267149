import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import Home from './pages/Home';
import About from './pages/AboutUs';
import OurProcess from './pages/OurProcess';
// import MobileAppDevelopment from './pages/MobileAppDevelopment';
// import WebPortalDevelopment from './pages/WebPortalDevelopment';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ThankYouPage from './pages/ThankYou';
import NotFoundPage from './pages/404';
import BlogsPage from './pages/Blogs';
import BlogPage from './pages/Blog';

// Utility to remove trailing slashes
const RemoveTrailingSlash = ({ children }) => {
    const location = useLocation()

    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
        const newPath = location.pathname.slice(0, -1) + location.search
        return <Navigate to={newPath} replace />
    }

    return children
}


const App = () => {
    return (
        <HelmetProvider>
            <Router>
                <RemoveTrailingSlash>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about-us" element={<About />} />
                        <Route path="/our-process" element={<OurProcess />} />
                        {/* <Route path="/mobile-app-development" element={<MobileAppDevelopment />}/>
                        <Route path="/web-portal-development" element={<WebPortalDevelopment />}/> */}
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/thank-you" element={<ThankYouPage />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="blogs" element={<BlogsPage />} />
                        <Route path=":slug" element={<BlogPage />} />
                        <Route path="404" element={<NotFoundPage />} />
                    </Routes>
                </RemoveTrailingSlash>
            </Router>
        </HelmetProvider>
    );
};

export default App;
