import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../CarouselSection.css";

// Import images
import ServicesCarousal1 from '../images/SPRK_default_preset_name_custom – 1.png';
import ServicesCarousal2 from '../images/SPRK_default_preset_name_custom – 2.png';
import ServicesCarousal3 from '../images/SPRK_default_preset_name_custom – 3.png';
import ServicesCarousal4 from '../images/SPRK_default_preset_name_custom – 4.png';
import ServicesCarousal5 from '../images/SPRK_default_preset_name_custom – 5.png';
import ServicesCarousal7 from '../images/SPRK_default_preset_name_custom – 7.png';
import ServicesCarousal8 from '../images/SPRK_default_preset_name_custom – 8.png';
import ServicesCarousal9 from '../images/SPRK_default_preset_name_custom – 11.png';
import ServicesCarousal11 from '../images/SPRK_default_preset_name_custom – 13.png';
import ServicesCarousal13 from '../images/SPRK_default_preset_name_custom – 15.png';
import ServicesCarousal14 from '../images/SPRK_default_preset_name_custom – 16.png';

const logos = [
    { src: ServicesCarousal1, caption: "Top App Development Design Agency" },
    { src: ServicesCarousal2, caption: "Top Mobile App Development Company" },
    { src: ServicesCarousal3, caption: "Best App Development Company" },
    { src: ServicesCarousal4, caption: "Top App Development Design Agency" },
    { src: ServicesCarousal5, caption: "Leading Application Development Agency" },
    { src: ServicesCarousal7, caption: "Top Mobile App Development Company" },
    { src: ServicesCarousal8, caption: "Best App Development Company" },
    { src: ServicesCarousal9, caption: "Leading Application Development Agency" },
    { src: ServicesCarousal11, caption: "Top Mobile App Development Company" },
    { src: ServicesCarousal13, caption: "Leading Application Development Agency" },
    { src: ServicesCarousal14, caption: "Best App Development Company" },
];
const CarouselSection = () => {
    const settings = {
        dots: true, // Enable dots
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        customPaging: (i) => <button>{i + 1}</button>, // Custom dot style
        appendDots: (dots) => {
            const visibleDots = dots.slice(0, 5); // Limit to 6 dots
            return <ul>{visibleDots}</ul>;
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <section className="unique-carousel-section">
                <div className="unique-carousel-wrapper">
                    <h2 className="unique-heading text-center">
                        Awards and <span style={{ color: '#2780C3' }}>Recognitions.</span>
                    </h2>
                    <p className="unique-description text-center">
                        Honored to be recognized as a top mobile app development company on leading <br />
                        industry platforms, reflecting our dedication to excellence and innovation.
                    </p>
                    <Slider {...settings}>
                        {logos.map((logo, index) => (
                            <div key={index} className="unique-carousel-item">
                                <img
                                    src={logo.src}
                                    alt={`Logo ${index + 1}`}
                                    className="unique-carousel-logo"
                                />
                                <p className="unique-carousel-caption">{logo.caption}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
        </section>
    );
};

export default CarouselSection;
