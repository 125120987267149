import HeaderSection from "../components/Header";
import FooterSection from "../components/Footer";
import ThankYouSection from "../components/ThankYouSection";
import PopupForm1 from "../components/PopupForm";

const ThankYouPage = () => {
    return (
        <>
            <HeaderSection />
            <ThankYouSection />
            <FooterSection />
            <PopupForm1 />
        </>
    );
};

export default ThankYouPage;
