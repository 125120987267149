import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedin, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import contactusbg from '../images/Frame 76.png'
import '../Contactform.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const ContactUsform = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); // Loading state
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    })
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const validateFormFields = (formFieldName = null) => {
        let isValid
        const regexes = {
            firstName: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            lastName: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            subject: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            phone: /^\+?\d{10,15}$/,
            message: /^(?!\s+$)[^\s].{0,1999}$/,
        }

        if (formFieldName) {
            isValid = regexes[formFieldName].test(formData[formFieldName])
            if (!isValid)
                document.querySelector(formFieldName !== 'message' ? `.contactuspg-section input[name=${formFieldName}]` : `.contactuspg-section textarea[name=${formFieldName}]`).classList.add('is-invalid')
            else
                document.querySelector(formFieldName !== 'message' ? `.contactuspg-section input[name=${formFieldName}]` : `.contactuspg-section textarea[name=${formFieldName}]`).classList.remove('is-invalid')
        } else {
            for (const key in regexes) {
                if (Object.prototype.hasOwnProperty.call(regexes, key)) {
                    const regex = regexes[key]
                    isValid = regex.test(formData[key])

                    if (!isValid)
                        document.querySelector(key !== 'message' ? `.contactuspg-section input[name=${key}]` : `.contactuspg-section textarea[name=${key}]`).classList.add('is-invalid')
                    else
                        document.querySelector(key !== 'message' ? `.contactuspg-section input[name=${key}]` : `.contactuspg-section textarea[name=${key}]`).classList.remove('is-invalid')

                    if (!isValid)
                        return false
                }
            }
            return true
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        // Email & phone validation
        if (!validateFormFields())
            return
        setLoading(true)
        await fetch("https://theappdesign.com/php_mailer/contact.php", {
            method: 'POST',
            body: JSON.stringify(formData)
        })
            .then(r => r.json())
            .then(({ success, message }) => {
                setLoading(false)
                if (success)
                    navigate('/thank-you')
                else
                    Swal.fire('Error', message, 'error')
            })
    }
    return (
        <section className="contactuspg-section py-5">
            <div className="container">
                {/* First Row - Heading and Subtext */}
                <div className="contactuspg-bg py-5">
                    {/* Second Row - 2 Columns */}
                    <div className="row">
                        {/* First Column - Contact Information */}
                        <div className="col-lg-5 contactuspg-info mb-4">
                            <div
                                className="contactuspg-info-box p-4"
                                style={{
                                    backgroundImage: `url(${contactusbg})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: '10px',
                                    color: '#fff',
                                }}
                            >
                                <h3>Contact Information</h3>
                                <p>Say something to start a live chat</p>
                                <a href='https://maps.app.goo.gl/bXkBHoZbGcbXQ4LH6' target='_blank' style={{ color: 'white', textDecoration: 'none' }}>
                                    <address>
                                        <FaMapMarkerAlt /> 43 3rd Ave 2nd Floor, Edison, NJ 08837<br />
                                    </address>
                                </a>
                                <a href="tel:+15515543283" className="contactuspg-link" target='_blank'>
                                    <FaPhoneAlt /> +1 (551) 554-3283
                                </a><br />
                                <a href="mailto:info@theappdesign.com" className="contactuspg-link" target='_blank'>
                                    <FaEnvelope /> info@theappdesign.com
                                </a>
                                {/* Social Icons */}
                                <div className="contactuspg-social-icons d-flex">
                                    <a href="https://www.facebook.com/TheAppDesign/" className="contactuspg-icon"><FaFacebookF /></a>
                                    <a href="https://www.instagram.com/theappdesign_/" className="contactuspg-icon"><FaInstagram /></a>
                                    <a href="https://www.linkedin.com/company/the-app-design/" className="contactuspg-icon"><FaLinkedin /></a>

                                </div>
                            </div>
                        </div>
                        {/* Second Column - Form */}
                        <div className="col-lg-7 contactuspg-form  p-4">
                            <form method='POST' onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input type="text" className="form-control contactuspg-input" onChange={handleChange} value={formData.firstName} name='firstName' placeholder="First Name*" required onKeyUp={() => validateFormFields('firstName')} />
                                        <div className="invalid-feedback">
                                            Not allowed more than 50 characters and it must be alphabetic
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <input type="text" className="form-control contactuspg-input" onChange={handleChange} value={formData.lastName} name='lastName' placeholder="Last Name*" required onKeyUp={() => validateFormFields('lastName')} />
                                        <div className="invalid-feedback">
                                            Not allowed more than 50 characters and it must be alphabetic
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input type="email" className="form-control contactuspg-input" onChange={handleChange} value={formData.email} name='email' placeholder="Email*" required onKeyUp={() => validateFormFields('email')} />
                                        <div className="invalid-feedback">
                                            Invalid Email address
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <input type="tel" className="form-control contactuspg-input" onChange={handleChange} value={formData.phone} name='phone' placeholder="Phone Number*" required onKeyUp={() => validateFormFields('phone')} />
                                        <div className="invalid-feedback">
                                            Phone number must be between 10 to 15 digits and can include "+" symbol.
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control contactuspg-input" onChange={handleChange} value={formData.subject} name='subject' placeholder="Subject*" required onKeyUp={() => validateFormFields('subject')} />
                                    <div className="invalid-feedback">
                                        Not allowed more than 50 characters and it must be alphabetic
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control contactuspg-input" rows="5" onChange={handleChange} value={formData.message} name='message' placeholder="Message*" required onKeyUp={() => validateFormFields('message')}></textarea>
                                    <div className="invalid-feedback">
                                        Whitespaces are not allowed in beginning & message should not be more than 2000 characters.
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="submit" className="btn btn-submit" disabled={loading}>
                                        {loading ? (
                                            <>
                                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                                <span role="status">Submitting...</span>
                                            </>
                                        ) : 'Submit Now'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ContactUsform;
