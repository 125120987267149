import React, { useState } from "react";
import { FaUser, FaEnvelope, FaPhone, FaCommentAlt, FaTimes } from 'react-icons/fa'; // Font Awesome icons
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import '../Popupform.css'; // Include the CSS file for custom styling


const PopupForm1 = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false); // Loading state
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
        // newsletter: false,
    });

    const validateFormFields = (formFieldName = null) => {
        let isValid
        const regexes = {
            name: /^[A-Za-z][A-Za-z\s]{0,49}$/,
            email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            phone: /^\+?\d{10,15}$/,
            message: /^(?!\s+$)[^\s].{0,1999}$/,
        }

        if (formFieldName) {
            isValid = regexes[formFieldName].test(formData[formFieldName])
            if (!isValid)
                document.querySelector(formFieldName !== 'message' ? `#popupForm input[name=${formFieldName}]` : `#popupForm textarea[name=${formFieldName}]`).classList.add('is-invalid')
            else
                document.querySelector(formFieldName !== 'message' ? `#popupForm input[name=${formFieldName}]` : `#popupForm textarea[name=${formFieldName}]`).classList.remove('is-invalid')
        } else {
            for (const key in regexes) {
                if (Object.prototype.hasOwnProperty.call(regexes, key)) {
                    const regex = regexes[key]
                    isValid = regex.test(formData[key])

                    if (!isValid)
                        document.querySelector(key !== 'message' ? `#popupForm input[name=${key}]` : `#popupForm textarea[name=${key}]`).classList.add('is-invalid')
                    else
                        document.querySelector(key !== 'message' ? `#popupForm input[name=${key}]` : `#popupForm textarea[name=${key}]`).classList.remove('is-invalid')

                    if (!isValid)
                        return false
                }
            }
            return true
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()

        // Email & phone validation
        if (!validateFormFields())
            return

        setLoading(true)

        await fetch(/*'http://localhost:9090/popup-email.php'*/"https://theappdesign.com/php_mailer/popup.php", {
            method: 'POST',
            body: JSON.stringify(formData)
        })
            .then(r => r.json())
            .then(({ success, message }) => {
                document.querySelector('button[data-bs-dismiss]').click()
                setLoading(false)
                if (success)
                    navigate('/thank-you')
                else
                    Swal.fire('Error', message, 'error')
            })
    }

    // Handle form input changes
    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="modal fade" id="popupForm" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h4 className="modal-title text-black">Get a Quote</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body pt-0">
                        <form method='POST' onSubmit={handleSubmit}>
                            <input type='hidden' name='title' value={formData.title} />
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Full name <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="name" placeholder="John Doe" name='name' value={formData.name} onChange={handleChange} onKeyUp={() => validateFormFields('name')} required />
                                <div className="invalid-feedback">
                                    Not allowed more than 50 characters and it must be alphabetic
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address <span className="text-danger">*</span></label>
                                <input type="email" className="form-control" id="email" placeholder="example@test.com" name='email' value={formData.email} onChange={handleChange} required onKeyUp={() => validateFormFields('email')} />
                                <div className="invalid-feedback">
                                    Invalid Email address
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Phone number <span className="text-danger">*</span></label>
                                <input type="tel" className="form-control" id="phone" placeholder="1234567890" name='phone' value={formData.phone} onChange={handleChange} required onKeyUp={() => validateFormFields('phone')} />
                                <div className="invalid-feedback">
                                    Phone number must be between 10 to 15 digits and can include "+" symbol.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message <span className="text-danger">*</span></label>
                                <textarea className="form-control" id="message" placeholder="Your message..." rows="5" name='message' onChange={handleChange} value={formData.message} required onKeyUp={() => validateFormFields('message')} ></textarea>
                                <div className="invalid-feedback">
                                    Whitespaces are not allowed in beginning & message should not be more than 2000 characters.
                                </div>
                            </div>
                            <div className="d-grid mb-3">
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {loading ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
                                            <span role="status">Submitting...</span>
                                        </>
                                    ) : 'Submit'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupForm1;
