import React from 'react';

const IndustriesSection = () => {
    return (
        <section className="industries-section">
            <div className="container">
                <h2 className="section-heading text-center">Empowering Results Through <span style={{ color: '#2780C3' }}>Collaboration</span></h2>
                <p className='industries-description'>We work together as a team to create apps that give great results, combining our diverse skills and perspectives to deliver exceptional outcomes that exceed expectations.</p>
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Real Estate</h3>
                            <p>Our custom real estate app enhances property browsing, simplifies transactions, and connects buyers and sellers seamlessly.</p>
                            <div className="tags">
                                <span className="badge">Listings</span>
                                <span className="badge">CRM</span>
                                <span className="badge">VR Tours</span>
                                <span className="badge">Tenant Management</span>
                                <span className="badge">Invest</span>
                                <span className="badge">Annually</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Health Care</h3>
                            <p>Our healthcare app simplifies appointments, health tracking, and communication for a more personal patient experience.</p>
                            <div className="tags">
                                <span className="badge">Telehealth</span>
                                <span className="badge">eRx</span>
                                <span className="badge">EMR</span>
                                <span className="badge">Remote Monitoring</span>
                                <span className="badge">Patient Portal</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Banking</h3>
                            <p>Give your customers a secure banking app for easy transactions, real-time account management, and seamless financial control anytime, anywhere.</p>
                            <div className="tags">
                                <span className="badge">Digital Banking</span>
                                <span className="badge">Safe Transactions</span>
                                <span className="badge">CRM</span>
                                <span className="badge">Contactless Payments</span>
                                <span className="badge">Money Tracker</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Entertainment</h3>
                            <p>Our entertainment app offers seamless streaming, personalized content, and easy access for your customers to their favorite shows and music.</p>
                            <div className="tags">
                                <span className="badge">Digital Entertainment</span>
                                <span className="badge">Live Streaming App</span>
                                <span className="badge">Watch Now</span>
                                <span className="badge">Offline Music</span>
                                <span className="badge">Create Your Playlist</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Fitness</h3>
                            <p>Our fitness app tracks workouts, sets goals, and provides personalized plans for your customers to help them stay fit and motivated.</p>
                            <div className="tags">
                                <span className="badge">Track Your Fitness</span>
                                <span className="badge">Wellness Tracker</span>
                                <span className="badge">Health Monitoring</span>
                                <span className="badge">Step Counter</span>
                                <span className="badge">Workout Planner</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="industry-card">
                            <h3>Education</h3>
                            <p>Our app offers an integrated set of eLearning elements, tools, and resources that support knowledge delivery and management. </p>
                            <div className="tags">
                                <span className="badge">VR Classroom</span>
                                <span className="badge">Student Portal</span>
                                <span className="badge">Analytics</span>
                                <span className="badge">CRM</span>
                                <span className="badge">Exams</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndustriesSection;
