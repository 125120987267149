import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import { useLocation } from "react-router-dom";
import PopupForm1 from '../components/PopupForm';

const NotFoundPage = () => {
    const { state: { message } } = useLocation()

    return (
        <div className="not-found-page">
            <HeaderSection />

            <section className="not-found-section" style={{ paddingTop: '200px', paddingBottom: '200px' }}>
                <div className="container">
                    <div className="text-center">
                        <h1 className="fw-semibold display-1">404</h1>
                        <h3>{message}</h3>
                    </div>
                </div>
            </section>

            <FooterSection />
            <PopupForm1 />
        </div>
    )
}

export default NotFoundPage
