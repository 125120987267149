import React, { useState } from 'react';
import HeaderSection from '../components/Header';
import FooterSection from '../components/Footer';
import BannerSection from '../components/banner';
import AcquireAppSection from '../components/acquire';
import IndustriesSection from '../components/industries';
import UniqueSection from '../components/unique';
import EngagingUiCarouselSection from '../components/EngagingUi';
import ContactSection from '../components/form';
import MobileAppsSection from '../components/mobileapp';
import FAQSection from '../components/faqs';
import WorkProcess from '../components/recognitions';
import Testinonials from '../components/testimonials';
import StepSection from '../components/steps';
import ServicesSection from '../components/ServicesSecSwap';

import { Helmet } from 'react-helmet-async';
import CarouselAwardSec from '../components/carouselaward';



import { Link } from 'react-router-dom';
import PopupForm1 from '../components/PopupForm';


const Home = () => {

    // ******************************** Start FAQ Section ******************************
    const faqData = [

        {
            question: "How much does it cost to design a mobile app?",
            answer: "Designing a mobile app can vary from $5,000 to $50,000 depending on several factors. A straightforward mobile app will usually be cheaper compared to high-end features."
        },
        {
            question: "How can I design a mobile app?",
            answer: "A mobile app can be designed, defining its purpose, wireframes created, UI/UX designed, the app developed, and finally, it can be tested. Expert teams work effectively and bring even higher-quality results."
        },
        {
            question: "What is mobile app UI UX design?",
            answer: "This includes creating attractive screens, making it easy for the user to navigate through the application and enjoy using it. Mobile app UI/UX design enables effective user interface (UI) design and overall user experience (UX) enhancement."
        },
        {
            question: "Who designs phone apps?",
            answer: "Designers design apps and developers work in app development. Usually, app-designing teams consist of designers and developers. Designers only focus on the UI and UX to be simplistic, while developers make the whole functionality of the application, whereby catering for programming and coding."
        },
        {
            question: "How to design UI UX for an Android app?",
            answer: "To design UI/UX for an Android app, create wireframes, follow Design principles, and test for usability across devices."
        }
    ];


    // ******************************** End FAQ Section ******************************



    return (
        <>
            <HeaderSection />
            <Helmet>
                <title>Mobile App Design Company | The App Design</title>
                <meta name="description" content="Mobile app design services for iOS and Android by a top mobile app design company: UI/UX design and expert guidance. Project success no matter what!" />
                <link rel="canonical" href="https://theappdesign.com" />
            </Helmet>

            <BannerSection />
            <AcquireAppSection />
            <IndustriesSection />
            <UniqueSection />
            <ServicesSection />
            {/* <CarouselAwardSec/> */}
            {/* <EngagingUiCarouselSection /> */}
            <StepSection />
            <MobileAppsSection />
            <UniqueSection />
            {/* <WorkProcess /> */}
            <CarouselAwardSec />
            <Testinonials />

            <div>
                <FAQSection faqData={faqData} />
            </div>
            <ContactSection />
            <FooterSection />

            <PopupForm1 />

        </>
    );
};

export default Home;
